/* eslint-disable react/prop-types */
import { getFirestore } from "firebase/firestore";
import { Helmet } from 'react-helmet'
import { Typography, Box, InputAdornment, Grid, Paper, TextField, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react';
import { getStorage } from 'firebase/storage'
import getFirebase from "../components/firebaseConfig.js"
import SiteHeader from '../components/header.js'
import { Search, ArrowBackIos, ArrowForwardIos, Add } from '@mui/icons-material';
import { css, jsx } from '@emotion/react'
import { fetchData, filterListingsHOC } from '../components/sharedFunctions.js'
import theme from '../components/theme.js'
import { ThemeProvider } from '@mui/material/styles';
import AltImageDisplay from '../components/visual_entry_components/altImageDisplay.js'
import Closed from "../components/closed.js";
/** @jsx jsx */
const sidebarStyle = {
	'&>div': {
		padding: "5px 30px 5px 30px"
	},
	[theme.breakpoints.down('sm')]: {
		'&>div': {
			padding: "5px"
		}
	}
}

css`    
    &>div{
        
        };
`

const textInputStyle = css`
background:white; 
width:95%;
margin-bottom:20px;
margin-top:20px;
& .MuiOutlinedInput-root{
    &.Mui-focused fieldset{
    borderColor: 'black';
    };
};
`
getFirebase()
const db = getFirestore()
const storage = getStorage()


const ListingSearchResult = (props) => {
	const { listingName, listingData, primaryImg, secondaryImgs, setIsDisplayingAlt } = props
	return (
		<Grid item xs={24} style={{ display: "flex", justifyContent: "center", minHeight: "380px" }} key={listingName}>
			<Paper elevation={10} style={{ border: "6px solid #E2221D", borderRadius: "25px", width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", }}>
				<Typography variant="h4" align="center" style={{ fontWeight: "bold" }}>
					{listingName}
				</Typography>
				<Grid container style={{ padding: "10px", display: "flex", alignItems: "center", height: "100%" }}>
					<Grid item xs={26} md={13} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
						<img src={primaryImg.content} style={{ maxWidth: "100%", maxHeight: '280px' }} id={listingName} />
						<Typography align="center" variant="caption" style={{ textDecoration: "underline", cursor: "pointer" }}
							onClick={() => {
								let images = []
								images.push(primaryImg.content)
								images = images.concat(secondaryImgs.map((img) => {
									return img.content
								}))

								setIsDisplayingAlt({
									name: listingName,
									images: images
								})

							}}
						>
							Show all images of item
						</Typography>
					</Grid>
					<Grid container item xs={26} md={13} whiteSpace="pre-line" style={{ display: "flex", alignItems: "center" }}>
						<Grid item xs={26} md={16} style={{ padding: "10px" }}>
							<Typography align="center" variant="body2">
								{listingData.description}
							</Typography>
						</Grid>
						<Grid item xs={26} md={8}>
							{listingData.oldPrice ?
								<Typography variant="h5" align="center" style={{ fontWeight: "bold", textDecoration:"line-through"}}>
									${listingData.oldPrice}
								</Typography>
								: 
								null
							}
							<Typography variant="h5" align="center" style={{ fontWeight: "bold" }}>
								${listingData.price}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	)
}

const AllListings = (props) => {
	const [listings, setListings] = useState([])
	const [resultPage, setResultPage] = useState(1)
	const [isDisplayingAlt, setIsDisplayingAlt] = useState(null)
	const [resultsPerPage, setResultsPerPage] = useState(10)
	const [dataFetchDone, setDataFetchDone] = useState(false)
	const { nameInput, filters, filterListings, selectItemType, ItemTypeEntry, BrandEntry,
		brandInput, deleteBrandFilter, setNameInput, addBrand, setBrandInput } = props


	useEffect(async () => {
		let listingData = await fetchData(db, storage)

		for (let i = 0; i < listingData.length; i++) {
			let randNum = Math.floor(Math.random() * listingData.length)
			let listing1 = listingData[i]
			let listing2 = listingData[randNum]
			listingData[i] = listing2
			listingData[randNum] = listing1
		}
		setListings(listingData)
		setDataFetchDone(true)
	}, [])

	let listingsJSX = []

	let resultPageNav = []

	let displayedListings = filterListings(listings)

	displayedListings.forEach((listing) => {
		let listingData = listing.data
		let listingName = listing.listingName
		let primaryImg = listing.primaryImg
		let secondaryImgs = listing.secondaryImgs
		let testCheck = new RegExp('test', 'g')

		if (!testCheck.test(listingName)) {
			listingsJSX.push(
				<ListingSearchResult listingData={listingData}
					listingName={listingName}
					primaryImg={primaryImg}
					secondaryImgs={secondaryImgs}
					setIsDisplayingAlt={setIsDisplayingAlt} />
			)
		}
	})
	const maxPageCount = Math.ceil(listingsJSX.length / resultsPerPage)

	listingsJSX = listingsJSX.slice(resultsPerPage * (resultPage - 1), Math.min(resultsPerPage * resultPage, listingsJSX.length))




	const listingsPageVisible = 5

	const sectionMinCount = Math.max(Math.floor(resultPage / listingsPageVisible) * listingsPageVisible, 1)
	for (let i = sectionMinCount; i <= Math.min(sectionMinCount + 4, maxPageCount); i++) {


		if (i == sectionMinCount && i != 1) {
			resultPageNav.push(
				<Grid item xs={4} display="flex" justifyContent="center">
					<Typography
						variant="subtitle1"
						sx={{
							cursor: "pointer",
							'&:hover': {
								textDecoration: "underline"
							}
						}}
						onClick={() => {
							window.scrollTo(0, 0);
							setResultPage(i - 1)
						}}
					>...
					</Typography>
				</Grid>
			)
		}

		resultPageNav.push(
			<Grid item xs={4} display="flex" justifyContent="center">
				<Typography
					variant="subtitle1"
					sx={{
						cursor: "pointer",
						'&:hover': {
							textDecoration: "underline"
						}
					}}
					onClick={() => {
						window.scrollTo(0, 0);
						setResultPage(i)
					}}
				>{i === resultPage ?
						<span style={{ fontWeight: "bold" }}>{i}</span>
						:
						<span>{i}</span>
					}
				</Typography>
			</Grid>
		)

		if (i == Math.min(sectionMinCount + 4, maxPageCount) && i != maxPageCount) {
			resultPageNav.push(<Grid item xs={4} display="flex" justifyContent="center">
				<Typography
					variant="subtitle1"
					sx={{
						cursor: "pointer",
						'&:hover': {
							textDecoration: "underline"
						}
					}}
					onClick={() => {
						window.scrollTo(0, 0);
						setResultPage(i + 1)
					}}
				>...
				</Typography>
			</Grid>)
		}

	}



	if (resultPage > displayedListings.length && displayedListings.length > 0 || resultPage > maxPageCount && maxPageCount > 0) {
		setResultPage(1)
	}

	return (
		<Box sx={{ position: "fixed", height: "100vh", width: "100vw", overflowX: "hidden", overflowY: "scroll", right: 0, paddingBottom: "20px" }}>
			<Helmet defer={false}>
				<title>Search through listings</title>
			</Helmet>
			<ThemeProvider theme={theme} style={{ position: "relative", width: "100%" }}>
				<SiteHeader currentPage="All listings" />
				{isDisplayingAlt ?
					<AltImageDisplay setIsDisplayingAlt={setIsDisplayingAlt} isDisplayingAlt={isDisplayingAlt} />
					:
					null
				}
				<Box sx={
					{
						display: 'inline-flex',
						padding: "20px 20px 0px 20px",
						justifyContent: "space-between",
						width: "100vw",
						[theme.breakpoints.down('sm')]: {
							padding: "20px 5px 0px 5px"
						}
					}
				}>
					<Box
						sx={{
							width: "30%", minWidth: "140px",
							backgroundColor: "white",
							borderRadius: "25px",
							[theme.breakpoints.down('sm')]: {
								width: "75%"
							}
						}}
					>
						<Paper elevation={13} style={{ borderRadius: "25px", position: "sticky" }}>
							<Box style={{ borderRadius: "25px" }} sx={sidebarStyle}>
								<Box style={{ backgroundColor: "#E2221D", borderRadius: "25px 25px 0px 0px", padding: "10px 10px 10px 35px", display: "flex", alignItems: "center" }}>
									<Typography variant="h2">Search</Typography>
								</Box>
								<Box>
									<TextField
										css={textInputStyle}
										variant="outlined"
										placeholder="Type in item name..."
										value={nameInput}
										onChange={(e) => {
											setNameInput(e.target.value)
										}}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<Search />
												</InputAdornment>
											)
										}}
									/>
									<Typography variant="subtitle2">
										Scroll down to filter by brand and item type.</Typography>
									<br />
									<Typography variant="subtitle2">
										Remember,
										you must schedule an appointment by calling us at
										416-539-9170 <span style={{ fontWeight: "bold" }}>
											before coming to our premises to buy an item.
										</span> We are located at 1273 Bloor St. West, Toronto, ON, M6H 1N7. </Typography>
								</Box>
							</Box>

							<Box sx={sidebarStyle} style={{ marginTop: "30px" }}>
								<Box style={{ backgroundColor: "#E2221D" }}>
									<Typography variant="h4" >Item type</Typography>
								</Box>
								<Box style={{ backgroundColor: "white", borderRadius: "25px", paddingRight: "30px" }}>
									<ItemTypeEntry filterName="Accessory" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Amplifier" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Audio Cables" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Bookshelf Speaker" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Cassette Deck" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="CD Player" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Center Speaker" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="DAC" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Equalizer" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Floor Standing Speaker" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Pre-Amplifier" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Pre-Amp & Power Amp Set" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Power Amplifier" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Receiver" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Reel-to-reel Tape Recorder" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Speaker Wires" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Sub-Woofer" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Tuner" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Tuner & Amp Set" category="itemType" handleChange={selectItemType} />
									<ItemTypeEntry filterName="Turntable" category="itemType" handleChange={selectItemType} />

								</Box>
								<Box style={{ backgroundColor: "#E2221D" }}>
									<Typography variant="h4" >Brand</Typography>
								</Box>
								<Box style={{ backgroundColor: "white", borderRadius: "25px" }}>
									<TextField
										css={textInputStyle}
										variant="outlined"
										placeholder="Type in brand name..."
										value={brandInput}
										onChange={(e) => {
											setBrandInput(e.target.value)
										}}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												addBrand()
											}
										}
										}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">

													<Add style={{ cursor: "pointer" }} onClick={addBrand} />

												</InputAdornment>
											)
										}}
									/>

									<Box style={{ display: "flex", flexWrap: "wrap" }}>
										{filters.brand.map(brandName => {
											return (
												<BrandEntry brand={brandName} deleteBrandFilter={deleteBrandFilter} key={brandName} />
											)
										}
										)}
									</Box>
								</Box>
							</Box>
						</Paper>
					</Box>
					<Box style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "70%" }}>
						<Box style={{ textAlign: "center", width: "100%" }}>
							<Typography variant="h2" align="center">Results</Typography>
						</Box>
						<Grid container columns={26} rowSpacing={5} style={{ width: "100%", marginTop: "15px", marginBottom: "20px", textAlign: "right", display: "flex", justifyContent: "center" }}>
							{dataFetchDone ?
								listingsJSX
								:
								<Typography marginTop="20px">Loading in listings...</Typography>
							}
						</Grid>
						<Grid container columns={32}
							sx={{
								display: "flex",
								justifyContent: "space-around",
								marginTop: "15px",
								maxWidth: "26%",
								'@media screen and (max-width: 1200px) and (min-width:601px)': {
									maxWidth: "45%"
								},
								'@media screen and (max-width: 600px)': {
									maxWidth: "75%"
								}
							}}
						>
							<Grid item md={2} xs={32}>
								<ArrowBackIos style={{ cursor: "pointer", margin: "0" }} onClick={() => {
									if (resultPage > 1) {
										window.scrollTo(0, 0)
										setResultPage(resultPage - 1)
									}
								}} />
							</Grid>
							{resultPageNav}
							<Grid item md={2} xs={32} display="flex" justifyContent="flex-end">
								<ArrowForwardIos style={{ cursor: "pointer", margin: "0" }} onClick={() => {
									if (resultPage < maxPageCount) {
										window.scrollTo(0, 0)
										setResultPage(resultPage + 1)
									}
								}}
								/>
							</Grid>
						</Grid>
						<Typography marginTop="20px">Results per page: </Typography>
						<TextField select defaultValue={10}>
							{[5, 10, 20, 50].map((count) => {
								return (
									<MenuItem key={count} value={count} id="count"
										onClick={() => {
											setResultsPerPage(count)
										}}
									>
										{count}
									</MenuItem>
								)
							})}
						</TextField>
						{dataFetchDone && listingsJSX.length == 0 ?
							<Typography marginTop="20px">Sorry, no results were found.</Typography>
							:
							null
						}

					</Box>
				</Box>
			</ThemeProvider>
		</Box>
	)
}

export default filterListingsHOC(AllListings)
